import crudActions from "@/store/templates/crud/actions";
import api from "@/api";
import { SET_IS_LOADING, SET_MODEL } from "../../templates/crud/mutation-types";
import { isNil, cloneDeep } from "lodash";
import store from "@/store";
import { FETCH } from "../../templates/crud/action-types";
import moment from "moment";

export default {
  async confirm({ commit, dispatch }, { id, force }) {
    try {
      const { entities, result } = await api.invoice.confirm(id, force);
      commit(SET_MODEL, { entities, id: result });
    } catch (e) {
      const message =
        !isNil(e.response?.data?.error) &&
        !isNil(e.response?.data?.error?.detail)
          ? e.response?.data?.error?.detail
          : e.response?.data?.message;
      dispatch(
        "snackbar/addItem",
        {
          text: message,
          color: "warning"
        },
        { root: true }
      );
    }
  },
  async pay({ commit }, invoiceId) {
    const { entities, result } = await api.invoice.pay(invoiceId);
    commit(SET_MODEL, { entities, id: result });
  },
  async destroy(context, invoiceId) {
    await api.invoice.destroy(invoiceId);
  },
  async pdf(context, invoiceNumber) {
    await api.invoice.pdf(invoiceNumber);
  },
  async creditNotePdf({ state }, payload) {
    const invoiceId = state.model.id;
    await api.creditNote.pdf(payload, invoiceId);
  },
  async deleteCreditNote({ state, commit, dispatch }, creditNoteId) {
    try {
      const invoiceId = state.model.id;
      await api.creditNote.destroy(creditNoteId, invoiceId);
      commit("removeCreditNote", creditNoteId);
    } catch (e) {
      const message =
        !isNil(e.response?.data?.error) &&
        !isNil(e.response?.data?.error?.detail)
          ? e.response?.data?.error?.detail
          : e.response?.data?.message;
      dispatch(
        "snackbar/addItem",
        {
          text: message,
          color: "warning"
        },
        { root: true }
      );
    }
  },
  async fetchInvoiceableOffers({ state, commit }) {
    commit("setIsLoadingOffers", true);
    const {
      entities,
      result
    } = await api.invoice.itemOffer.fetchInvoiceableOffers(
      state.model.company,
      state.distributedUntill
    );
    commit("setIsLoadingOffers", false);
    commit("setOfferEntities", entities);
    commit("setInvoiceableOffers", result);
  },
  async rejectOffer({ dispatch }, { offerId, reason, comment }) {
    await api.offer.reject(offerId, reason, comment);
    await dispatch("fetchInvoiceableOffers");
  },
  async rejectAndRemoveOfferFromItem({ commit }, { offerId, reason, comment }) {
    await api.offer.reject(offerId, reason, comment);
    commit("removeOfferFromItem", offerId);
  },
  async rejectAndRemoveFromInvoice(
    { commit, dispatch },
    { invoiceId, invoiceItemId, offerId, reason, comment }
  ) {
    const result = await api.invoice.rejectAndRemoveFromInvoice(
      invoiceId,
      invoiceItemId,
      offerId,
      reason,
      comment
    );
    if (typeof result === "string") {
      dispatch(
        "snackbar/addItem",
        { text: result, color: "info" },
        { root: true }
      );
      return true;
    } else {
      commit(SET_MODEL, { entities: result.entities, id: result.result });
      dispatch(`itemOffers${invoiceItemId}/${FETCH}`);
      return false;
    }
  },
  async updateItem({ commit }, { invoiceId, item }) {
    const { entities, result } = await api.invoice.updateItem(invoiceId, item);
    commit(SET_MODEL, { entities, id: result });
  },
  async deleteItem({ commit, dispatch }, { invoiceId, itemId }) {
    const result = await api.invoice.deleteItem(invoiceId, itemId);
    if (typeof result === "string") {
      dispatch(
        "snackbar/addItem",
        { text: result, color: "info" },
        { root: true }
      );
      return true;
    } else {
      commit(SET_MODEL, { entities: result.entities, id: result.result });
      return false;
    }
  },
  async addOfferItemsToInvoice({ state, commit }) {
    let itemsToAdd = [];
    const creditOffers = state.invoiceableOffers.filter(
      offerId => state.offer[offerId].isCreditingIssued
    );
    const invoiceableOffers = state.invoiceableOffers.filter(
      offerId => !state.offer[offerId].isCreditingIssued
    );
    if (invoiceableOffers.length > 0) {
      let item = {};
      const offers = invoiceableOffers
        .map(offerId => state.offer[offerId])
        .sort((a, b) => moment(a.distributedAt).diff(moment(b.distributedAt)));
      const distributedFrom = moment(offers[0].distributedAt).format(
        "DD-MM-YYYY"
      );
      const distributedTo = moment(state.distributedUntill).format(
        "DD-MM-YYYY"
      );
      item.offers = invoiceableOffers;
      item.unitPrice = offers.reduce((a, { price }) => a + price, 0);
      item.totalPrice = offers.reduce((a, { price }) => a + price, 0);
      item.quantity = 1;
      item.distributedFrom = distributedFrom;
      item.distributedTo = distributedTo;
      item.description = `Leadgeneratie (${distributedFrom} - ${distributedTo}) aantal leads: ${offers.length}`;
      itemsToAdd.push(item);
    }
    if (creditOffers.length > 0) {
      let item = {};
      const offers = creditOffers.map(offerId => state.offer[offerId]);
      item.offers = creditOffers;
      item.unitPrice = -offers.reduce((a, { price }) => a + price, 0);
      item.totalPrice = -offers.reduce((a, { price }) => a + price, 0);
      item.quantity = 1;
      item.description = `Verrekening afgekeurde leads`;
      itemsToAdd.push(item);
    }
    const { entities, result } = await api.invoice.addItems(
      state.model.id,
      itemsToAdd
    );
    commit("setDistributedUntill", null);
    commit("setInvoiceableOffers", []);
    commit(SET_MODEL, { entities, id: result });
  },
  async addItemToInvoice({ state, commit }) {
    let item = cloneDeep(state.tempItem);
    item.totalPrice = state.tempItem.unitPrice * state.tempItem.quantity;
    const { entities, result } = await api.invoice.addItems(state.model.id, [
      item
    ]);
    commit(SET_MODEL, { entities, id: result });
  },
  ...crudActions(api.invoice, "invoice"),
  async [FETCH]({ commit }, { id }) {
    try {
      window.Echo.connector.pusher.config.auth.headers["Authorization"] =
        "Bearer " + store.getters["auth/accessToken"];
      window.Echo.private(`Invoice.${id}`).listen(
        ".invoice.pdf.generated",
        e => {
          console.log(e);
          commit("setPdfDownloadable");
        }
      );
      commit(SET_IS_LOADING, true);
      const { entities, result } = await api.invoice.fetch(id);
      entities.entity[result].creditNotes.forEach(creditNote => {
        window.Echo.connector.pusher.config.auth.headers["Authorization"] =
          "Bearer " + store.getters["auth/accessToken"];
        window.Echo.private(`CreditNote.${creditNote.id}`).listen(
          ".credit-note.pdf.generated",
          e => {
            console.log(e);
            commit("setCreditNotePdfDownloadable", creditNote.id);
          }
        );
      });
      commit(SET_MODEL, { entities, id: result });
      commit(SET_IS_LOADING, false);
      return result;
    } catch (e) {
      console.error("Error with CRUD module fetch action:", e);
    }
  }
};
