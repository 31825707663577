import { isNil } from "lodash";
import { DELETE, FETCH, SAVE } from "./action-types";
import { SET_IS_LOADING, SET_MODEL } from "./mutation-types";

export default (apiModule, entityName) => ({
  async [SAVE]({ state, commit, dispatch }) {
    commit(SET_IS_LOADING, true);
    const isCreate = isNil(state.model.id);
    const { entities, result } = isCreate
      ? await apiModule.create(state.model)
      : await apiModule.update(state.model.id, state.model);
    commit(SET_MODEL, { entities, id: result });
    commit(SET_IS_LOADING, false);

    dispatch(
      "snackbar/addCrudSuccess",
      { entityName, isCreate },
      { root: true }
    );

    return result;
  },
  async [FETCH]({ commit }, { id }) {
    try {
      commit(SET_IS_LOADING, true);
      const { entities, result } = await apiModule.fetch(id);
      commit(SET_MODEL, { entities, id: result });
      commit(SET_IS_LOADING, false);
      return result;
    } catch (e) {
      console.error("Error with CRUD module fetch action:", e);
    }
  },
  async [DELETE]({ dispatch }, { id }) {
    const { result } = await apiModule.remove(id);

    dispatch(
      "snackbar/addItem",
      {
        text: `${entityName} deleted successfully.`,
        color: "success"
      },
      { root: true }
    );

    return result;
  }
});
